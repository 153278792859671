.comparison-table {
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;
  }
  
  .comparison-table th, .comparison-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .comparison-table th {
    background-color: #f4f4f4;
    text-align: center;
  }
  
  .comparison-table td {
    text-align: center;
  }
  
  .comparison-table td.match {
    background-color: #c8e6c9; /* Light green */
    color: green;
  }
  
  .comparison-table td.mismatch {
    background-color: #ffcdd2; /* Light red */
    color: red;
  }
  
  .comparison-table td.unknown {
    background-color: #fffde7; /* Light yellow */
  }
  .center {
    margin: auto;
    width: 50%;
    /* border: 3px solid green; */
    padding: 10px;
  }  