.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  z-index: 1000;
  width: 700px;
  /* Adjust the width as needed */
  height: 500px;
}

.popup_inner {
  max-width: 1000px;
  max-height: 80vh;
  overflow-y: auto;
}

.popup button {
  margin-top: 20px;
}

.close_button {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  font-size: 10px;
  font-weight: bold;
}

.popup_table {
  width: 100%;
  border-collapse: collapse;
}

.popup_table th,
.popup_table td {
  border: 1px solid #dddddd;
  padding: 8px;
  text-align: left;
}

.popup_table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.awb-details-box {
  display: flex;
  align-items: center;
  gap: 5px;
}

.info-circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
}

.info-circle svg {
  color: #007bff;
}

.header {
  font-size: 13px;
}

.loader {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  margin: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}