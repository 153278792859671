




/* @import "bootstrap";
/* Define custom colors */
/* :root {
  --deep-blue: #004080;
  --steel-gray: #808080;
  --bright-green: #4CAF50;
  --pure-white: #FFFFFF;
  --sunset-orange: #FF5722;
  --sky-blue: #00BFFF; 
} */

/* Override Bootstrap variables with custom colors */
/* :root {
  --primary-color: var(--deep-blue);
  --secondary: var(--steel-gray);
  --success: var(--bright-green);
  --info: var(--sky-blue);
  --warning: var(--sunset-orange);
  --danger: var(--sunset-orange);
  --light: var(--pure-white);
  --dark: var(--steel-gray);
} */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.theme1 .navbar {
  /* background-color: #EAF4FD; */
  background-color: #bac4de;
  color: white;
}

.theme1 .nav-link {
  color: white;
}

.theme1 .nav-item {
  color: white;
}

.theme1 .navbar-collapse .nav-pills .active {
  color: white;
  background-color: #536bac;
}

.theme1 .navbar-collapse .nav-pills .show .nav-link {
  color: white;
  background-color: #536bac;
}

.theme1 .navbar-collapse .nav-pills .dropdown-menu .dropdown-item:active {
  color: white;
  background-color: #536bac;
}

.theme1 .navbar-collapse .navbar-nav .show .dropdown-menu .dropdown-item:active {
  color: white;
  background-color: #536bac;
}

.theme2 .navbar {
  background-color: #bfbfbf;
}

.theme2 .nav-link {
  color: white;
}

.theme2 .nav-item {
  color: white;
}

.theme2 .navbar-collapse .nav-pills .active {
  color: white;
  background-color: #536bac;
}

.theme2 .navbar-collapse .nav-pills .show .nav-link {
  color: white;
  background-color: #536bac;
}

.theme2 .navbar-collapse .nav-pills .dropdown-menu .dropdown-item:active {
  color: white;
  background-color: #536bac;
}

.theme2 .navbar-collapse .navbar-nav .show .dropdown-menu .dropdown-item:active {
  color: white;
  background-color: #536bac;
}


.theme3 .navbar {
  /* background-color: #DAFDFA; */
  /* background-color: #439822; // #7ead64;*/
  background-color: #b8d2ad;
  color: white;
}

.theme3 .nav-link {
  color: white;
}

.theme3 .nav-item {
  color: white;
}

.theme3 .navbar-collapse .nav-pills .active {
  color: white;
  background-color: #536bac;
}

.theme3 .navbar-collapse .nav-pills .show .nav-link {
  color: white;
  background-color: #536bac;
}

.theme3 .navbar-collapse .nav-pills .dropdown-menu .dropdown-item:active {
  color: white;
  background-color: #536bac;
}

.theme3 .navbar-collapse .navbar-nav .show .dropdown-menu .dropdown-item:active {
  color: white;
  background-color: #536bac;
}

.nav-tabs .nav-link.active {
  color: black !important;
}

.nav-tabs .nav-link {
  color: #536bac !important;
}

.btn-primary {
  background-color: #536bac !important;
}

.btn-outline-primary {
  border-color: #536bac !important;
  color: #536bac !important;
}

.btn-outline-primary:hover, .btn-outline-primary:visited, .btn-outline-primary:active, .btn-outline-primary:focus {
  background-color: #536bac !important;
  border-color: #536bac !important;
  color: white !important;
}

.form-check-input:checked {
  background-color: #536bac !important;
  border-color: #536bac !important;
}

.section-title {
  color: #536bac;
}

.table-header {
  background-color: #e0e0e0;
}

.plain-accordion .accordion-button:not(.collapsed), .plain-accordion .accordion-button:focus {
  color:  #536bac;
  background-color: white;
  box-shadow: none;
  /*box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);*/
}

.modal-90w{
  width:70vw !important;
  max-width: none!important;
}

.search-icon{
  position: absolute;
  right: 10px;
  top: 8px;
  z-index: 9999;
}

.custom-dropdown {
  position: relative;
}
.custom-dropdown i {
  position: absolute;
  right: 16px;
  height: 100%;
  top: 10px;
}
.custom-dropdown .form-control {
  padding-right: 36px;
}